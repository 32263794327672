import { ICategoryDTO } from '@/types';
import { categoriesURL } from './urls';
import api from './axiosInstance';

export const getCategories = async (): Promise<ICategoryDTO[]> => {
  try {
    const response = await api.get<ICategoryDTO[]>(categoriesURL);

    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw new Error('bad Request');
  }
};
