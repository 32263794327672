import api from './axiosInstance';
import { ICategoryDTO, IItemDTO } from '@/types';

export const setNewRows = async (
  url: string,
  data: ICategoryDTO | IItemDTO
): Promise<any> => {
  try {
    const response = await api.post<Promise<any>>(url, data);

    return response.data;
  } catch (error: any) {
    console.error('Response data:', error.response);
    throw error;
  }
};
