import {ICategoryDTO, IItemDTO} from '@/types';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import {getItems} from '../service/getItems';
import {useCategories} from './useCategories';

const ProductsContext = createContext<{
  products?: IItemDTO[];
  setProducts?: Dispatch<SetStateAction<IItemDTO[]>>;
  getProductsWithCategoryNames?: (items: IItemDTO[]) => any;
}>({});

const ProductsContextProvider = ({children}: PropsWithChildren) => {
  const [products, setProducts] = useState<IItemDTO[]>([]);
  const {categories} = useCategories();

  const getProductsWithCategoryNames = (items: IItemDTO[]) => {
    return items.map((item: IItemDTO) => {
      const category: ICategoryDTO | undefined = categories?.find(
        (category) => category._id === item.categoryId
      );

      return {
        ...item,
        categories: category ? category.name : 'Unknown Category',
      };
    });
  };

  const fetchItems = async () => {
    try {
      const response = await getItems();

      setProducts(getProductsWithCategoryNames(response));
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [categories]);

  return (
    <ProductsContext.Provider
      value={{
        products,
        setProducts,
        getProductsWithCategoryNames,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export {ProductsContext, ProductsContextProvider};
