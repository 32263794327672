export const inputInfoProduct = [
  {label: 'Название', name: 'name', type: 'text', id: 'name'},
  {label: 'Цена', name: 'price', type: 'number', id: 'price'},
  {label: 'Категория', name: 'categoryId', type: 'select', id: 'categoryId'},
  {label: 'Количество', name: 'quantity', type: 'number', id: 'quantity'},
  {
    label: 'Описание',
    name: 'description',
    type: 'description',
    id: 'description',
  },
];
