import {ICategoryDTO, IItemDTO} from '@/types';
import api from './axiosInstance';

export const updateCurrentRow = async (
  url: string,
  data: IItemDTO | ICategoryDTO
): Promise<any> => {
  try {
    const id = data._id;
    delete data?._id;
    delete data?.createdAt;
    const response = await api.put<Promise<any>>(`${url}/${id}`, data);

    return response.data;
  } catch (error: any) {
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }

    throw error;
  }
};
