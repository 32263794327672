import React, {useState} from 'react';
import {useCategories} from '../../contexts';
import {getCategories} from '../../service/getCategories';
import {removeCurrentRow} from '../../service/removeCurrentRow';
import {categoriesURL} from '../../service/urls';
import {ColumnsCategories} from '../dataGrid/categories/columns';
import {DataGrid} from '../dataGrid/DataGrid';
import './categories-tab.css';
import {EditCategoryModal} from './EditCategoryModal';

const CategoriesTab: React.FC = () => {
  const [isCategoryModalOpen, setIsCategoryModalOpen] =
    useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>('');
  const {categories, setCategories} = useCategories();

  const editCategory = (id: string): void => {
    setIsCategoryModalOpen(true);
    setCurrentId(id);
  };

  const handleRemoveItem = async (id: string): Promise<void> => {
    try {
      await removeCurrentRow(categoriesURL, id);
      const updatedCategories = await getCategories();

      setCategories!(updatedCategories!);
    } catch (err) {
      console.error(err);
    }
  };

  const addCategory = (): void => {
    setIsCategoryModalOpen(true);
    setIsNew(true);
  };

  const closeEditCategoryModal = (): void => {
    setIsCategoryModalOpen(false);
    setIsNew(false);
  };

  return (
    <>
      <DataGrid
        removeItem={handleRemoveItem}
        rows={categories!}
        addItem={addCategory}
        editItem={editCategory}
        getColumns={ColumnsCategories}
      />
      {isCategoryModalOpen && (
        <EditCategoryModal
          id={currentId}
          isOpen={isCategoryModalOpen}
          onClose={closeEditCategoryModal}
          isNew={isNew}
        />
      )}
    </>
  );
};

export {CategoriesTab};
