import { IItemDTO } from '@/types';
import { iconsURL } from './urls';
import api from './axiosInstance';

export const getIcons = async (): Promise<any> => {
  try {
    const response = await api.get<IItemDTO[]>(iconsURL);

    return response?.data;
  } catch (error: any) {
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request data:', error.request);
    } else {
      console.error('Error message:', error.message);
    }

    throw error;
  }
};
