import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import {GridToolbarContainer, GridToolbarProps} from '@mui/x-data-grid';

export const AddInToolBar: React.FC<GridToolbarProps> = ({
  handleClickAddNewRow,
}) => {
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClickAddNewRow}
      >
        {'Создать'}
      </Button>
    </GridToolbarContainer>
  );
};
