import {CustomFieldPropsDTO} from '@/types';
import {FormControl, InputLabel} from '@mui/material';
import {ErrorMessage, Field} from 'formik';
import './CustomInputs.css';
import {IconInput} from './IconInput';

const CustomIconField: React.FC<CustomFieldPropsDTO> = ({
  error,
  info,
  disabled,
}) => {
  return (
    <div className={'custemInput'}>
      <FormControl fullWidth sx={{m: 1}}>
        <InputLabel shrink variant={'standard'}>
          {'Иконка'}
        </InputLabel>
        <Field
          variant={'standard'}
          as={IconInput}
          sx={{mt: '20px'}}
          error={Boolean(error)}
          {...info}
          disabled={disabled}
        />
        <ErrorMessage className={'error'} name={info.name} component={'div'} />
      </FormControl>
    </div>
  );
};

export {CustomIconField};
