export const inputInfoLogin = [
  {label: 'Login', name: 'login', type: 'text', id: 'name', gen: 'aa'},
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    id: 'password',
    gen: 'aa',
  },
];
