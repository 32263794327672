import axios from 'axios';
import { authSigninURL } from './urls';
import { setAuthToken } from './setAuthToken ';

export async function authorization(username: string, password: string) {
  try {
    const response = await axios.post(authSigninURL, {
      username,
      password,
    });

    const { accessToken, refreshToken } = response?.data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setAuthToken(accessToken);

    return accessToken;
  } catch (error: any) {
    if (error.response) {
      console.error('Login failed:', error.response.data);
      throw new Error(
        `Login failed: ${error.response.data.message || error.response.statusText
        }`
      );
    } else {
      console.error('Login failed:', error.message);
      throw new Error(`Login failed: ${error.message}`);
    }
  }
}
