import {IItemDTO} from '@/types';
import api from './axiosInstance';
import {itemsURL} from './urls';

export const getItems = async (): Promise<IItemDTO[]> => {
  try {
    const response = await api.get<IItemDTO[]>(itemsURL + '?take=1000');

    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw Error('bad request');
  }
};
