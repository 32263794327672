import * as Yup from 'yup';

export const validationSchemaProducts = Yup.object().shape({
  name: Yup.string()
    .required('Обязательное поле')
    .min(3, 'Название должно содержать не менее 3 букв'),
  description: Yup.string().required('Описание обязательно'),
  price: Yup.number()
    .typeError('Должно быть числом')
    .required('Обязательное поле')
    .integer('Должно быть числом')
    .min(0, 'Должно быть больше или равно 0')
    .test('is-num', 'Must be a valid number', (value) => !isNaN(value)),
  categoryId: Yup.string().required('Обязательное поле'),
  quantity: Yup.number()
    .typeError('Должно быть числом')
    .required('Обязательное поле')
    .integer('Должно быть числом')
    .min(0, 'Должно быть больше или равно 0')
    .test('is-num', 'Must be a valid number', (value) => !isNaN(value)),
});
