import Box from '@mui/material/Box';
import {DataGrid as MUIDataGrid} from '@mui/x-data-grid';
import React, {useState} from 'react';
import {RemoveItemModal} from '../Modals/RemoveItemModal';
import {AddInToolBar} from './AddInToolBar';
import './DataGrid.css';

interface IDataGridProps {
  rows: any[];
  removeItem: (id: string) => void;
  addItem: () => void;
  editItem: (id: string) => void;
  getColumns: any;
}

const DataGrid: React.FC<IDataGridProps> = ({
  rows,
  removeItem,
  addItem,
  editItem,
  getColumns,
}) => {
  const [isRemoveItemModalOpen, setIsRemoveItemModalOpen] =
    useState<boolean>(false);
  const [leavingElementId, setLeavingElementId] = useState<string>();

  const closeRemoveModal = (): void => {
    setIsRemoveItemModalOpen(false);
  };

  const openRemoveModal = (id: string) => {
    setIsRemoveItemModalOpen(true);
    setLeavingElementId(id);
  };

  const onRemoveItem = async () => {
    await removeItem(leavingElementId as string);
    closeRemoveModal();
  };

  const styles = {
    box: {
      'height': '90vh',
      'width': '90%',
      '& .actions': {
        color: 'text.secondary',
      },
      '& .textPrimary': {
        color: 'text.primary',
      },
      '& .super-app-theme--header': {
        outline: 'none !important',
      },
    },
    muiDataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
      '.MuiDataGrid-cell--textLeft': {
        cursor: 'pointer',
        textAlign: 'left',
      },
      '& .MuiDataGrid-cell': {
        borderRight: '1px solid #e0e0e0',
      },
      '& .MuiDataGrid-columnHeader': {
        borderRight: '1px solid #e0e0e0',
        textAlign: 'left',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        justifyContent: 'flex-start',
      },
      '& .MuiAvatarGroup-root.MuiAvatarGroup-root': {
        display: 'flex',
        justifyContent: 'start',
      },
    },
  };

  return (
    <Box sx={styles.box}>
      <RemoveItemModal
        isOpen={isRemoveItemModalOpen}
        onClose={closeRemoveModal}
        removeItem={onRemoveItem}
      />
      <MUIDataGrid
        disableColumnMenu
        disableColumnResize
        disableColumnFilter
        disableColumnSorting
        rows={rows}
        getRowId={(row) => row._id}
        onRowClick={(params: any) => editItem(params.id)}
        columns={getColumns(openRemoveModal, editItem)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[15]}
        sx={styles.muiDataGrid}
        rowHeight={85}
        isCellEditable={(_: any) => false}
        slots={{
          toolbar: AddInToolBar,
        }}
        slotProps={{
          toolbar: {handleClickAddNewRow: addItem},
        }}
      />
    </Box>
  );
};

export {DataGrid};
