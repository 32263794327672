import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Aligner } from '../../../core-component/aligner';
import { getIcons } from '../../../service/getIcons';

const IconInput: React.FC<any> = (props) => {
  const [icons, setIcons] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedIcon, setSelectedIcon] = useState(props.value[0]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const newIcons = await getIcons();
    setIcons(newIcons);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const chooseIcon = (icon: string) => () => {
    // setSelectedIcon(icon);
    const event = {
      target: {
        name: 'images',
        value: [icon],
      },
    };
    props!.onChange(event);
    onCloseModal();
  };

  return (
    <>
      <Box {...props} onClick={openModal} style={{cursor: 'pointer'}}>
        <Aligner>
          <img width={30} height={30} src={props.value?.[0]} />
          <EditIcon fontSize={'small'} />
        </Aligner>
      </Box>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={{position: 'absolute'}} className={'icon-input__modal'}>
          {icons?.map((icon: string) => {
            return (
              <Button onClick={chooseIcon(icon)} key={icon}>
                <img width={30} height={30} src={icon} />
              </Button>
            );
          })}
        </Box>
      </Modal>
    </>
  );
};

export { IconInput };

